import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../lib/utils';
import { ButtonLoadingAnimation } from '../LoadingAnimation';

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background',
    'transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 outline-none focus:outline-none focus:ring focus:ring-gray-400',
  ),
  {
    variants: {
      variant: {
        default:
          'bg-gray-100 text-md text-primary-foreground hover:bg-gray-300 ' +
          'rounded-md hover:transition-all hover:duration-200',
        primary:
          'bg-gray-900 text-white hover:bg-gray-700 hover:disabled:bg-gray-900 ' +
          'dark:bg-primary dark:text-gray-900 dark:hover:bg-primaryHover dark:hover:disabled:bg-primary',
        secondary:
          'bg-transparent border border-gray-900 text-gray-900 hover:bg-gray-900 ' +
          'text-white dark:bg-primary dark:text-primary dark:hover:bg-primary dark:hover:text-gray-900',
        outline:
          'border border-input bg-background hover:bg-accent ' + 'hover:text-accent-foreground !text-sm leading-[1.2]',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        'badge-btn': 'text-gray-500 hover:bg-accent hover:text-accent-foreground group rounded-md gap-2 focus:ring-0',
        link:
          'bg-transparent border-0 rounded-none p-0 h-auto leading-normal ' +
          'align-baseline text-gray-600 font-medium hover:underline hover:disabled:no-underline',
        inputIconButton: 'text-md text-gray-400 hover:text-primary dark:text-whiteAlpha-500 dark:hover:text-primary',
        unstyled: 'font-inherit',
        'gray-outline':
          'text-gray-600 border border-gray-200 bg-background hover:bg-accent dark:text-grayDark-50 dark:border-grayDark-500',
      },
      size: {
        default: 'text-sm h-10 px-4 py-2',
        xs: 'text-xs h-7 rounded-md px-3 py-2',
        sm: 'text-sm h-8 rounded-md px-3',
        lg: 'text-sm h-11 rounded-md px-8',
        icon: 'text-sm h-10 w-10',
        'icon-sm': 'text-sm h-8 w-8',
        'no-padding': 'text-sm h-10',
        unstyled: 'h-auto px-0 py-0',
        'badge-btn': 'p-2 h-7 text-xs',
      },
      colorScheme: {
        gray: 'bg-gray-100 text-gray-800',
        red: 'bg-red-600 text-white hover:bg-red-700 font-semibold',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export type TButtonVariants = VariantProps<typeof buttonVariants>['variant'];

const Button = React.forwardRef<HTMLButtonElement, ButtonProps & { isLoading?: boolean }>(
  ({ className, variant, size, asChild = false, isLoading, colorScheme, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className,
            colorScheme,
          }),
        )}
        ref={ref}
        {...props}
      >
        {isLoading ? ButtonLoadingAnimation() : props.children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
