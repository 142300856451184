'use client';

import { useEffect, type ReactNode, useMemo, useState } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '../../lib/utils';
import { useDisclosure } from '../../hooks/useDisclosure';
import { Button } from './button';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './command';
import { Checkbox } from './checkbox';

type OptionType = { value: string; label: ReactNode; showCheckbox?: boolean; searchBy?: string | null };

interface ComboboxProps {
  options: OptionType[];
  // biome-ignore lint/suspicious/noExplicitAny: was like this
  onSelect: (value: any) => void;
  defaultValue?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  notFoundMessage?: string;
  shouldCloseOnSelect?: boolean;
  TriggerComponent?: ReactNode;
  onOpenChange?: () => void;
  multipleCheckboxValues?: string[];
  enableSearch?: boolean;
  className?: string;
}

export function Combobox({
  options,
  onSelect,
  defaultValue,
  searchPlaceholder = 'Search...',
  placeholder = '...',
  notFoundMessage = 'Not found',
  shouldCloseOnSelect = true,
  TriggerComponent,
  onOpenChange,
  multipleCheckboxValues,
  enableSearch = true,
  className,
}: ComboboxProps) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [value, setValue] = useState(defaultValue);
  const currentLabel = useMemo(() => options.find(option => option.value === value)?.label, [value, options]);

  const handleChangeOnOpen = () => {
    onOpenChange?.();
    onToggle();
  };

  useEffect(() => {
    if (!multipleCheckboxValues?.length) {
      setValue(defaultValue);
    }
  }, [multipleCheckboxValues?.length, defaultValue]);

  return (
    <Popover open={isOpen} onOpenChange={handleChangeOnOpen}>
      {TriggerComponent ?? (
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={isOpen}
            aria-label='Organization selector'
            className="w-[250px] justify-between flex-nowrap"
          >
            <span className="overflow-hidden overflow-ellipsis">{currentLabel || placeholder}</span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent align="start" className={cn('w-[250px] p-0', className)}>
        <Command>
          {enableSearch && <CommandInput placeholder={searchPlaceholder} />}
          <CommandList
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#E4E4E7 transparent',
            }}
          >
            <CommandEmpty>{notFoundMessage}</CommandEmpty>
            <CommandGroup>
              {options.map(option => (
                <CommandItem
                  key={option.value}
                  value={option.searchBy ?? option.value}
                  className={cn(
                    'cursor-pointer',
                    option.showCheckbox
                      ? '[&[data-selected=true]>button]:opacity-100'
                      : 'flex items-center justify-between',
                  )}
                  onSelect={currentValue => {
                    const newValue = option.searchBy ? option.value : currentValue;

                    onSelect(newValue);
                    setValue(newValue);
                    shouldCloseOnSelect && onClose();
                  }}
                >
                  {option.showCheckbox && (
                    <Checkbox
                      className={cn(
                        'w-4 h-4 mr-2 rounded-sm border border-gray-400 opacity-0',
                        multipleCheckboxValues?.includes(option.value) && 'opacity-100',
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        onSelect(option.value);
                        setValue(option.value);
                      }}
                      checked={multipleCheckboxValues?.includes(option.value)}
                    />
                  )}
                  {option.label}
                  {!option.showCheckbox && (
                    <Check
                      className={cn('ml-2 h-4 w-4 text-gray-400', value === option.value ? 'opacity-100' : 'opacity-0')}
                    />
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
